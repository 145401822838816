{
  "common": {
    "and": "и",
    "show": "Показать",
    "showAll": "Показать все",
    "search": "Поиск",
    "showMore": "Показать ещё",
    "hide": "Скрыть",
    "more": "Ещё",
    "readMore": "Читать подробнее",
    "notChosen": "Не выбрано",
    "noOptions": "Нет подходящих вариантов.",
    "unitsOfMeasurement": {
      "label": "Единицы измерения",
      "options": {
        "squareMeter": "м²",
        "squareFoot": "фут²"
      }
    },
    "from": "от",
    "to": "до",
    "of": "из",
    "in": "в",
    "or": "или",
    "yes": "да",
    "no": "нет",
    "go": "Перейти",
    "send": "Отправить",
    "add": "Добавить",
    "edit": "Редактировать",
    "cancel": "Отмена",
    "details": "Подробнее",
    "inDevelopment": "Функционал в разработке",
    "supportRequestMessage": "При работе приложения произошла ошибка. Пожалуйста, обратитесь в техподдержку",
    "weekdays": {
      "short": {
        "monday": "Пн",
        "tuesday": "Вт",
        "wednesday": "Ср",
        "thursday": "Чт",
        "friday": "Пт",
        "saturday": "Сб",
        "sunday": "Вс"
      }
    },
    "months": {
      "full": {
        "january": "Январь",
        "february": "Февраль",
        "march": "Март",
        "april": "Апрель",
        "may": "Май",
        "june": "Июнь",
        "july": "Июль",
        "august": "Август",
        "september": "Сентябрь",
        "october": "Октябрь",
        "november": "Ноябрь",
        "december": "Декабрь"
      },
      "short": {
        "january": "Янв",
        "february": "Фев",
        "march": "Мар",
        "april": "Апр",
        "may": "Май",
        "june": "Июн",
        "july": "Июл",
        "august": "Авг",
        "september": "Сен",
        "october": "Окт",
        "november": "Ноя",
        "december": "Дек"
      },
      "genitive": {
        "january": "января",
        "february": "февраля",
        "march": "марта",
        "april": "апреля",
        "may": "мая",
        "june": "июня",
        "july": "июля",
        "august": "августа",
        "september": "сентября",
        "october": "октября",
        "november": "ноября",
        "december": "декабря"
      }
    },
    "time": {
      "at": "в",
      "from": "с",
      "to": "до",
      "up": "по",
      "label": {
        "from": "Время с",
        "to": "Время до"
      }
    },
    "terms": {
      "day": "дней | {count} день | {count} дня | {count} дней"
    },
    "today": "Сегодня",
    "tomorrow": "Завтра",
    "upload": "Загрузить",
    "available": "Доступно",
    "language": "Язык",
    "download": "Скачать",
    "all": "Все",
    "contacts": "Контакты",
    "save": "Сохранить",
    "noPhoto": "Нет фото",
    "countries": {
      "prepositional": {
        "ru": "России",
        "ge": "Грузии",
        "kz": "Казахстане",
        "uz": "Узбекистане",
        "ae": "ОАЭ",
        "id": "Индонезии",
        "cy": "Кипре",
        "th": "Таиланде"
      }
    },
    "downloadApp": "Скачивайте наше приложение"
  },
  "pageTitles": {
    "home": "Главная",
    "login": "Вход",
    "signup": "Регистрация",
    "signupInvitation": "Регистрация - приглашение",
    "forgotPassword": "Восстановление пароля",
    "resetPassword": "Сброс пароля",
    "buildingPage": "{value}",
    "presentationBuildingPage": "{value}",
    "presentationBuildingPageFromSuggestion": "{value}",
    "market": "Каталог",
    "suggestions": "Подборки",
    "suggestionPage": "Подборка №{value}",
    "notFound": "Страница не найдена",
    "forbidden": "Доступ запрещен",
    "suggestionsManagement": "Управление подборками",
    "clients": "Клиенты",
    "changeList": "Обновления",
    "developerPage": "{value}",
    "journal": "Журнал",
    "journalNews": "Новости",
    "profileDelete": "Удаление профиля"
  },
  "feedback": {
    "label": "Обратная связь",
    "options": {
      "suggestIdea": "Предложить идею",
      "dataError": "Сообщить об ошибке в актуальности данных",
      "serviceError": "Сообщить об ошибке в работе сервиса"
    }
  },
  "favoritesLinks": {
    "label": "Избранное",
    "reset": "Сбросить",
    "add": "Добавить страницу",
    "confirm": {
      "title": "Вы точно хотите сбросить все избранные страницы?",
      "toasts": {
        "success": "Избранные страницы успешно сброшены",
        "error": "Произошла ошибка при сбросе избранных страниц"
      }
    }
  },
  "cookie": {
    "title": "Уведомление о cookie",
    "text": "Мы используем файлы cookie для того, чтобы сделать работу сайта более удобной и безопасной. Используя данный сайт, вы даете согласие на использование файлов cookies.",
    "readMore": "Подробнее",
    "accept": "Хорошо, не показывать больше"
  },
  "currency": {
    "label": "Валюта",
    "options": {
      "rub": "₽",
      "usd": "$",
      "eur": "€",
      "kzt": "₸",
      "thb": "฿",
      "gbp": "£"
    }
  },
  "pagination": {
    "goToPage": "Перейти"
  },
  "auth": {
    "description": {
      "label": "Estatyx",
      "welcome": "Добро пожаловать!",
      "into": "Пожалуйста, войдите в свою учетную запись"
    },
    "login": {
      "label": "Войти"
    },
    "resetPassword": {
      "label": "Сбросить пароль",
      "description": "Ваш новый пароль должен отличаться от ранее использовавшихся паролей.",
      "setPassword": "Установить новый пароль",
      "toast": {
        "success": "Пароль успешно изменен",
        "error": "Во время изменения пароля произошла ошибка"
      }
    },
    "signup": {
      "label": "Зарегистрироваться",
      "iAgreeText": "Соглашаюсь на обработку персональных данных в соответствии c",
      "privatePolicy": "политикой конфиденциальности",
      "licenseAgreement": "условиями лицензионного соглашения"
    },
    "register": {
      "label": "Регистрация",
      "toast": {
        "label": {
          "success": "Вы успешно зарегистрировались!",
          "error": "Во время регистрации произошла ошибка"
        }
      }
    },
    "organisation": {
      "description": {
        "selectOrganisation": "Необходимо выбрать организацию",
        "someOrganisation": "Вы зарегистрированы в нескольких организациях. Выберите организацию, в которой продолжите работу в приложении или создайте новую.",
        "attention": "Внимание!",
        "warning": "Нажимая кнопку \"Войти\" будет создана новая организация."
      },
      "actions": {
        "selectOrganisation": {
          "error": "Во время выбора организации произошла ошибка"
        },
        "createOrganisation": {
          "label": "Создать новую организацию",
          "error": "Во время создания организации произошла ошибка"
        }
      },
      "fired": {
        "title": "Доступ ограничен",
        "error": "Для восстановления доступа обратитесь к отвественному в вашей организации или создайте новую организацию",
        "create": "Создать организацию"
      }
    },
    "password": {
      "label": "Пароль",
      "placeholder": "Введите пароль"
    },
    "passwordConfirm": {
      "label": "Пароль ещё раз",
      "placeholder": "Повторите пароль",
      "error": "Пароли не совпадают"
    },
    "forgotPassword": {
      "label": "Забыли пароль?",
      "description": "Введите свой адрес электронной почты, и мы отправим вам инструкции по сбросу пароля.",
      "send": "Отправить",
      "toast": {
        "label": {
          "success": "Ссылка для восстановления пароля отправлена на email : {value}!",
          "error": "Произошла ошибка с отправкой пароля на ваш email "
        }
      }
    },
    "haveAccount": {
      "label": "Уже есть аккаунт?"
    },
    "username": {
      "label": "Логин",
      "placeholder": "Введите электронную почту или телефон",
      "error": "Поле должно быть номером телефона или адресом электронной почты"
    },
    "name": {
      "label": "Имя",
      "placeholder": "Введите имя",
      "error": "Поле Имя обязательно для заполнения"
    },
    "lastName": {
      "label": "Фамилия",
      "placeholder": "Введите фамилию"
    },
    "userEmail": {
      "label": "E-mail",
      "placeholder": "Введите электронную почту",
      "error": "Поле должно быть адресом электронной почты"
    },
    "userPhone": {
      "label": "Номер телефона",
      "placeholder": "Введите телефон",
      "error": "Поле должно быть номером телефона"
    },
    "backendValidation": {
      "error": "Имя пользователя или пароль неверны"
    },
    "invitationSignup": {
      "messages": {
        "success": "Создаваемый Вами аккаунт будет добавлен в организацию {value}.",
        "linkError": "Ссылка приглашения некорректна. Запросите новую ссылку или напишите в тех.поддержку {value}",
        "error": "Не удалось получить информацию об организации. Ошибка: {value}"
      },
      "toLogin": "Присоединиться с существующего аккаунта",
      "loginInfoMessage": "Ваш аккаунт будет добавлен в организацию {value}"
    },
    "exchangeToken": {
      "toast": {
        "label": {
          "error": "Не удалось авторизоваться в CRM"
        }
      }
    }
  },
  "organisation": {
    "form": {
      "id": {
        "label": "ID организации"
      },
      "createAt": {
        "label": "Подключение организации"
      },
      "name": {
        "label": "Название",
        "placeholder": "Введите название организации"
      },
      "email": {
        "label": "E-mail",
        "placeholder": "Введите электронную почту"
      },
      "site": {
        "label": "Сайт организации",
        "placeholder": "Введите сайт или оставьте поле пустым"
      },
      "phone": {
        "label": "Основной телефон",
        "placeholder": "+ 7 901 234 56 78"
      },
      "country": {
        "label": "Страна"
      },
      "type": {
        "label": "Тип аккаунта",
        "placeholder": "+7 901 234 56 78"
      },
      "city": {
        "label": "Город",
        "placeholder": {
          "label": "Введите город и выберите из списка"
        }
      },
      "actions": {
        "save": {
          "label": "Сохранить"
        },
        "fireUser": {
          "label": "Вы точно хотите уволить {firstName} {lastName} ?"
        }
      },
      "toast": {
        "success": {
          "label": "Данные об организации успешно сохранены!"
        },
        "error": {
          "label": "Во время сохранения данных произошла ошибка."
        }
      },
      "toasts": {
        "fireUser": {
          "success": "Пользователь успешно уволен!",
          "error": "Во время увольнения пользователя произошла ошибка"
        },
        "updateRole": {
          "success": "Статус успешно изменен!",
          "error": "Во время изменения статуса произошла ошибка"
        }
      }
    },
    "types": {
      "agency": {
        "label": "Агенство недвижимости"
      },
      "privateRealtor": {
        "label": "Частный риелтор"
      },
      "developer": {
        "label": "Застройщик"
      },
      "integrator": {
        "label": "Интегратор/Партнер Битрикс24"
      },
      "managementCompany": {
        "label": "Управляющая компания"
      },
      "other": {
        "label": "Другое"
      }
    },
    "moderation": {
      "onModeration": {
        "label": "На модерации",
        "title": "Ваша организация отправлена на модерацию",
        "text": "Доступ к сервису будет предоставлен после проверки"
      },
      "denied": {
        "label": "Доступ ограничен",
        "title": "Доступ ограничен",
        "text": "Для уточнения подробностей обратитесь к менеджеру по работе с партнерами"
      },
      "contacts": {
        "label": "Контакты для связи"
      }
    },
    "settings": {
      "page": {
        "toggle": {
          "options": {
            "profile": "Профиль",
            "settings": "Настройки",
            "integration": "Интеграция",
            "employees": "Сотрудники",
            "roles": "Роли"
          }
        },
        "profile": {
          "title": "Профиль компании"
        },
        "settings": {
          "title": "Настройки"
        },
        "integration": {
          "title": "Интеграция"
        },
        "employees": {
          "title": "Сотрудники",
          "invitation": {
            "link": "Ссылка для приглашения",
            "tooltip": {
              "copy": "Копировать",
              "change": "Изменить"
            }
          },
          "table": {
            "head": {
              "id": "ID",
              "phone": "Телефон",
              "email": "Почта",
              "role": "Роль"
            }
          }
        },
        "roles": {
          "title": "Роли",
          "user": "Пользователь",
          "admin": "Администратор"
        }
      },
      "form": {
        "profile": {
          "id": "ID организации:",
          "connectionTime": "Подключение организации:",
          "about": {
            "title": "О компании",
            "name": {
              "label": "Название",
              "placeholder": "Название организации"
            },
            "type": {
              "label": "Тип компании"
            }
          },
          "address": {
            "title": "Местонахождение",
            "country": {
              "label": "Выберите страну",
              "placeholder": "Страна"
            },
            "city": {
              "label": "Выберите город",
              "placeholder": "Город"
            }
          },
          "contacts": {
            "title": "Контакты",
            "phone": {
              "label": "Основной телефон",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            }
          }
        }
      }
    }
  },
  "validation": {
    "messages": {
      "min": "Поле {label} должно состоять минимум из {length} символов",
      "minValue": "Поле {label} должно быть не меньше {min}",
      "max": "Поле {label} должно состоять максимум из {length} символов",
      "required": "{label} это обязательное поле",
      "url": "Поле {label} должно быть корректным URL адресом",
      "oneFieldRequired": "{firstLabel} это обязательное поле, если поле \"{secondLabel}\" не заполнено"
    }
  },
  "address": {
    "select": {
      "button": {
        "label": "Страна"
      },
      "modal": {
        "title": {
          "choose": "Выбор",
          "country": "Страны",
          "region": "Региона",
          "city": "Города"
        }
      }
    },
    "allCountries": "Все страны",
    "country": {
      "placeholder": "Введите страну"
    },
    "region": {
      "placeholder": "Введите регион",
      "empty": "ЖК не найдено"
    },
    "city": {
      "placeholder": "Введите город"
    },
    "toast": {
      "error": "Во время загрузки произошла ошибка"
    }
  },
  "building": {
    "entityName": "ЖК",
    "name": {
      "default": "Без названия"
    },
    "promotions": {
      "label": "Акции ЖК",
      "allPromotions": "Акции всех ЖК",
      "showAllPromotions": "Смотреть акции всех ЖК",
      "showButton": "Смотреть",
      "promotionsNotFound": "Акции не найдены",
      "priceWithoutPromo": "Цена показана без учёта акций и скидок",
      "promotionInterval": "Акция действует",
      "unlimited": "бессрочно"
    },
    "classes": {
      "label": "Класс ЖК",
      "business": {
        "label": "Бизнес"
      },
      "comfort": {
        "label": "Комфорт"
      },
      "economy": {
        "label": "Эконом"
      },
      "elite": {
        "label": "Элит"
      }
    },
    "developers": {
      "notFound": "К сожалению, в выбранном вами регионе пока что нет статистики по застройщикам"
    },
    "banks": {
      "notFound": "К сожалению, в выбранном вами регионе пока что нет статистики по банкам"
    },
    "agencies": {
      "notFound": "К сожалению, в выбранном вами регионе пока что нет статистики по агенствам"
    },
    "popular": {
      "label": "Популярные ЖК",
      "jkNotFound": "К сожалению, в выбранном вами регионе пока что нет статистики по популярным ЖК"
    },
    "constructionProgress": {
      "quarter": {
        "label": "{value} квартал"
      },
      "photos": {
        "label": {
          "full": "нет фотографий | {count} фотография | {count} фотографии | {count} фотографий"
        }
      }
    },
    "houses": {
      "label": {
        "short": "нет корпусов | {count} к. | {count} к. | {count} к.",
        "full": "нет корпусов | {count} корпус | {count} корпуса | {count} корпусов"
      },
      "allHouses": "Все корпуса"
    },
    "contacts": {
      "label": "Контакты",
      "forCommunication": "Контакты для связи"
    },
    "withoutContacts": {
      "label": "Нет контактов"
    },
    "timeConstraints": {
      "quarter": {
        "label": {
          "short": "кв."
        },
        "options": {
          "first": "1",
          "second": "2",
          "third": "3",
          "fourth": "4"
        }
      },
      "year": {
        "label": {
          "short": "г.",
          "full": "год"
        }
      },
      "yearQuarter": "{quarter} кв. {year} г.",
      "salesStart": {
        "label": "Старт продаж"
      },
      "keysGetting": {
        "label": "Выдача ключей",
        "gettingNow": "Идёт передача"
      },
      "deadLine": {
        "label": "Срок сдачи"
      }
    },
    "flatsInfo": {
      "flats": {
        "label": {
          "full": "Квартиры",
          "short": "Квар-тиры"
        },
        "count": "Кол-во",
        "fullCountLabel": "Кол-во квартир в продаже",
        "flatsTotalCountLabel": "Кол-во квартир"
      },
      "flatRoomsCount": {
        "label": "Комнат",
        "one": "1",
        "two": "2",
        "three": "3",
        "four": "4",
        "five": "5",
        "six": "6",
        "seven": "7",
        "eight": "8",
        "nine": "9+",
        "studio": "Студия",
        "notIndicated": {
          "label": "Количество комнат не указано",
          "full": "не указано",
          "short": "не указ."
        },
        "openPlan": "Свободная планировка"
      },
      "flatRoomsCountBuildingCard": {
        "one": "1 к. кв.",
        "two": "2 к. кв.",
        "three": "3 к. кв.",
        "four": "4 к. кв.",
        "five": "5 к. кв.",
        "six": "6 к. кв.",
        "seven": "7 к. кв.",
        "eight": "8 к. кв.",
        "nine": "9+ к. кв.",
        "studio": "студ.",
        "notIndicated": {
          "label": "Количество комнат не указано",
          "full": "не указано",
          "short": "не указ."
        },
        "openPlan": "Свободная планировка"
      },
      "totalArea": {
        "label": "Площадь",
        "from": "от {value} м²"
      },
      "price": {
        "label": "Цена",
        "from": "от {value}"
      }
    },
    "metroInfo": {
      "distance": "{value} мин"
    },
    "motivation": {
      "label": "Мотивация"
    },
    "escrow": {
      "label": "Эскроу",
      "options": {
        "true": "Есть",
        "false": "Нет"
      }
    },
    "actions": {
      "motivation": {
        "label": "Мотивация",
        "commission": "Комиссия до {value}%",
        "linkDescriptionPartOne": "Условия выплат и размер",
        "linkDescriptionPartSecond": "комиссии читайте по",
        "link": "ссылке",
        "commissionSum": "До {amount} {currency}",
        "notFound": "Комиссия не указана"
      },
      "generalPlan": {
        "label": "Ген. план"
      },
      "videoReview": {
        "label": "Видеообзор"
      },
      "video": {
        "label": "Видео"
      },
      "map": {
        "label": "Карта"
      },
      "layout": {
        "label": "Отделка"
      },
      "panorama": {
        "label": "Панорама"
      },
      "aerialPanorama": {
        "label": "Аэропанорама"
      },
      "addToSuggestion": {
        "label": "Добавить в подборку"
      },
      "priceStatistics": {
        "label": "Динамика цен на м²"
      },
      "addedToSuggestion": {
        "label": "Добавили в подборку"
      },
      "linkToSuggestion": {
        "label": "Ссылка на подборку"
      },
      "linkToPresentation": {
        "label": "Ссылка на презентацию",
        "toast": {
          "success": "Ссылка скопирована"
        }
      },
      "lookAtTheMap": {
        "label": "Посмотреть на карте"
      },
      "booking": {
        "label": "Бронировать"
      },
      "fixingTheClient": {
        "label": "Фиксация клиента"
      },
      "fixViewing": {
        "label": "Запись на просмотр"
      },
      "constructionProgress": {
        "label": "Ход строительства",
        "emptyConstructionProgress": "Информация о ходе строительства отсутствует."
      },
      "addToFavorites": {
        "label": "В избранное"
      },
      "addToComparison": {
        "label": "В сравнение"
      },
      "lookAtTheCondition": {
        "label": "Условия / Вознаграждение / Регламент"
      },
      "chessboard": {
        "label": "Шахматка"
      }
    },
    "page": {
      "media": "Фото и видео",
      "totalArea": "Квартиры",
      "about": "О ЖК { value }",
      "decoration": "Отделка",
      "advantages": "Преимущества",
      "flats": {
        "label": {
          "full": "Квартиры",
          "short": "Квартиры"
        }
      },
      "constructionProgress": {
        "label": {
          "full": "Ход строительства",
          "short": "Ход стр-ва"
        }
      },
      "files": "Файлы",
      "mortgage": "Ипотека",
      "ambassador": {
        "title": "Узнайте всё у эксперта",
        "subtitle": "Мы являемся единственной компанией на рынке, которая предоставляет самый большой выбор недвижимости и проводит анализ ликвидности каждого объекта. Специалисты Estatyx знают, как безопасно Вам приобрести квартиру мечты и получать с них высокий доход.",
        "userInfo": "Амбассадор в {country}",
        "actions": {
          "contactExpert": {
            "label": "Связаться с экспертом",
            "messageForWhatsApp": "Добрый день.%0AЯ по поводу ЖК \"{buildingName}\"%0A{buildingLink}"
          },
          "bookViewing": {
            "label": "Записаться на демонстрацию",
            "messageForWhatsApp": "Добрый день.%0AХочу записаться на демонстрацию ЖК \"{buildingName}\"%0A{buildingLink}"
          }
        }
      }
    },
    "image": {
      "alt": "Изображение ЖК"
    },
    "state": {
      "label": "Стадия строительства дома",
      "viewLabel": "стадия строительства дома",
      "viewValue": "%{value}",
      "options": {
        "built": "Дом построен, но не сдан",
        "handOver": "Сдан",
        "unfinished": "Строится"
      }
    },
    "presentation": {
      "actions": {
        "returnToSuggestion": {
          "label": "К подборке"
        }
      },
      "bookViewing": {
        "textToSend": "Хочу записаться на просмотр ЖК {building}. Когда можем посмотреть?"
      },
      "flats": {
        "houses": {
          "managerChosen": "Объекты, подобранные риелтором"
        }
      }
    }
  },
  "flat": {
    "entityName": "Квартиры",
    "popular": {
      "label": "Популярные квартиры",
      "flatsNotFound": "К сожалению, в выбранном вами регионе пока что нет статистики по популярным квартирам"
    },
    "decorationType": {
      "label": "Отделка",
      "emptyDecoration": "Информация об отделках отсутствует.",
      "default": "Отделка по умолчанию",
      "options": {
        "label": "Варианты отделок",
        "withoutPreparation": {
          "short": "без отд.",
          "full": "Без отделки"
        },
        "preparation": {
          "short": "подгот.",
          "full": "Подготовка"
        },
        "preFinishing": {
          "short": "подчист.",
          "full": "Предчистовая"
        },
        "fine": {
          "short": "чист.",
          "full": "Чистовая"
        },
        "furnished": {
          "short": "с м.",
          "full": "С мебелью"
        },
        "partlyFurnished": {
          "short": "част. с м.",
          "full": "С мебелью (частично)"
        }
      }
    },
    "windowViewType": {
      "label": "Вид из окон",
      "options": {
        "street": {
          "label": "На улицу"
        },
        "yard": {
          "label": "Во двор"
        },
        "mountains": {
          "label": "На горы"
        },
        "yardAndStreet": {
          "label": "На улицу и двор"
        },
        "sea": {
          "label": "На море"
        },
        "river": {
          "label": "На реку"
        },
        "city": {
          "label": "На город"
        }
      }
    },
    "cardinalDirections": {
      "options": {
        "north": {
          "label": "на север"
        },
        "south": {
          "label": "на юг"
        },
        "west": {
          "label": "на запад"
        },
        "east": {
          "label": "на восток"
        },
        "northeast": {
          "label": "на северо-восток"
        },
        "southeast": {
          "label": "на юго-восток"
        },
        "southwest": {
          "label": "на юго-запад"
        },
        "northwest": {
          "label": "на северо-запад"
        }
      }
    },
    "paymentType": {
      "label": "Оплата",
      "options": {
        "mortgage": "Ипотека",
        "cash": "Наличные",
        "certificate": "Сертификат",
        "maternityCapital": "Мат. капитал",
        "installmentPlan": "Рассрочка"
      }
    },
    "contractType": {
      "label": "Договор",
      "options": {
        "assignment": "Уступка",
        "purchaseAndSaleAgreement": "ДКП"
      }
    },
    "totalArea": {
      "label": "Общая пл-дь",
      "value": "{value} м²",
      "from": "от {value} м²",
      "to": "до {value} м²"
    },
    "kitchenArea": {
      "label": "Пл-дь кухни",
      "value": "{value} м²"
    },
    "floorNumber": {
      "label": "Этаж",
      "value": "{value} эт.",
      "from": "от {value} эт.",
      "to": "до {value} эт.",
      "action": {
        "label": "Выберите этаж"
      }
    },
    "lastFloor": {
      "label": "Последний"
    },
    "notFirstFloor": {
      "label": "Не первый"
    },
    "price": {
      "from": "от {amount} {currency}",
      "to": "до {amount} {currency}"
    },
    "pricePerSquareMeter": "{amount} {currency}/м²",
    "status": {
      "label": "Доступные",
      "options": {
        "free": "В продаже",
        "booked": "Забронировано",
        "sold": "Продано",
        "unavailable": "Недоступно"
      }
    },
    "flatNumber": "Квартира № {value}",
    "section": "секц. {value}",
    "table": {
      "entityName": "Таблица",
      "section": "Секция",
      "floor": "Этаж",
      "flatNumber": "№ кв.",
      "totalArea": "Пл-дь общая",
      "kitchenArea": "Пл-дь кухни",
      "windowViewType": "Вид из окна",
      "cardinalDirection": "Сторона света",
      "price": "Общая ст-ть",
      "pricePerSquareMeter": "Ст-ть за м²",
      "status": "Статус",
      "building": "ЖК",
      "agentMotivation": "Комиссия"
    },
    "card": {
      "flatNumber": "№ {value}"
    },
    "grid": {
      "entityName": "Плитки"
    },
    "chessboard": {
      "entityName": "Квартиры на шахматке"
    },
    "order": {
      "full": "{value}-ая очередь",
      "short": "{value} оч."
    },
    "button": {
      "book": {
        "label": "Забронировать"
      },
      "showAvailableFlats": {
        "show": {
          "label": "Показать недоступные квартиры"
        },
        "hide": {
          "label": "Скрыть недоступные квартиры"
        }
      },
      "bookViewing": {
        "label": "Записаться на просмотр",
        "messageForWhatsApp": "Хочу записаться на просмотр квартиры № {flatNumber} в ЖК {buildingName} в доме {houseName}%0AЦена - {price}%0AПлощадь - {totalArea}%0AПланировка - {layoutName}%0AОтделка - {decorationType}%0A%0AКогда можем посмотреть?",
        "messageInLayoutForWhatsApp": "Хочу записаться на просмотр квартиры c планировкой - {layoutName} в ЖК {buildingName} в доме {houseName}%0AЦена - {price}%0AПлощадь - {totalArea}%0A%0AКогда можем посмотреть?",
        "messageDefaultField": "не указано"
      },
      "bookFlat": {
        "label": "Забронировать",
        "messageForWhatsApp": "Хочу забронировать квартиру № {flatNumber} в ЖК {buildingName} в доме {houseName}%0AЦена - {price}%0AПлощадь - {totalArea}%0AПланировка - {layoutName}%0AОтделка - {decorationType}%0A%0A",
        "messageInLayoutForWhatsApp": "Хочу забронировать квартиру c планировкой - {layoutName} в ЖК {buildingName} в доме {houseName}%0AЦена - {price}%0AПлощадь - {totalArea}%0A%0AКогда можем посмотреть?",
        "messageDefaultField": "не указано"
      }
    },
    "statistics": {
      "price": {
        "title": "Динамика цен за м²",
        "toggle": {
          "all": "Все",
          "studio": "Студия",
          "one": "1 к. квартира",
          "two": "2 к. квартира",
          "three": "3 к. квартира"
        },
        "forSquareMeter": "Цена за {currency} м²",
        "table": {
          "date": "Дата",
          "newBuildingPerSquareMeter": "Новостройка за м²",
          "button": "Посмотреть все"
        },
        "notFound": "К сожалению, статистика не найдена"
      }
    }
  },
  "layout": {
    "entityName": "Планировки",
    "image": {
      "alt": "Планировка"
    }
  },
  "developer": {
    "entityName": "Застройщик",
    "houses": {
      "handOver": "Домов нет | сдан в | сданы в | сдано в",
      "unfinished": "Домов нет | строится в | строятся в | строятся в"
    },
    "foundationYear": "год основания компании",
    "page": {
      "description": {
        "title": "О застройщике",
        "fullShowButton": "Читать подробнее"
      },
      "contacts": {
        "info": {
          "text": "Чтобы посмотреть контакты застройщика,",
          "login": "войдите",
          "signUp": "зарегистрируйтесь"
        }
      },
      "advantages": "Преимущества",
      "buildings": {
        "title": "ЖК от застройщика",
        "showButton": "Показать ещё {count} ЖК"
      },
      "flats": {
        "title": "Квартиры от застройщика",
        "showButton": "Показать ещё {count} квартир | Показать ещё {count} квартиру | Показать ещё {count} квартиры | Показать ещё {count} квартир"
      }
    },
    "promotions": {
      "label": "Акции застройщика",
      "allPromotions": "Акции всех застройщиков",
      "showAllPromotions": "Смотреть акции всех застройщиков",
      "showButton": "Смотреть",
      "promotionsNotFound": "Акции не найдены"
    }
  },
  "house": {
    "count": "{count} домов|{count} дом|{count} дома|{count} домов",
    "type": {
      "label": "Тип дома",
      "options": {
        "block": "Блочный",
        "frame": "Каркасный",
        "monolithicFrame": "Монолитно-каркасный"
      }
    },
    "facadeType": {
      "label": "Фасад",
      "options": {
        "plaster": "Штукатурка",
        "ventilatedFacade": "Вентилируемый фасад",
        "glass": "Стеклянный"
      }
    },
    "parkingType": {
      "label": "Паркинг",
      "options": {
        "underground": "Подземный",
        "indoor": "Крытый",
        "inYard": "Во двор"
      }
    },
    "lifts": {
      "label": "Лифт",
      "noLifts": "Нет лифта",
      "liftsExist": "Есть лифт",
      "passengerLiftsCount": {
        "label": "{count} пассажирских лифтов|{count} пассажирский лифт|{count} пассажирских лифта|{count} пассажирских лифтов"
      },
      "cargoLiftsCount": {
        "label": "{count} грузовых лифтов|{count} грузовой лифт|{count} грузовых лифта|{count} грузовых лифтов"
      }
    }
  },
  "entityActions": {
    "filter": {
      "promotions": {
        "hasPromotions": "Участвует в акции"
      },
      "entitiesFilter": {
        "placeholder": "Укажите ЖК, застройщика",
        "noCountryPlaceholder": "Сперва необходимо выбрать страну"
      },
      "flatFilter": {
        "kitchenArea": {
          "label": "Площадь кухни",
          "from": "Площадь кухни от {value} м²",
          "to": "Площадь кухни до {value} м²",
          "action": {
            "label": "Выберите площадь"
          }
        },
        "totalArea": {
          "label": "Площадь",
          "from": "Площадь от {value} м²",
          "to": "Площадь до {value} м²"
        },
        "price": {
          "label": "Цена",
          "from": "Цена от {value}",
          "to": "Цена до {value}"
        },
        "flatRoomsCount": {
          "one": "1к. кв.",
          "two": "2к. кв.",
          "three": "3к. кв",
          "four": "4к. кв",
          "five": "5к. кв",
          "six": "6к. кв",
          "studio": "студ."
        },
        "flatNumber": {
          "placeholder": "Введите номер квартиры"
        }
      },
      "houseFilter": {
        "deadLineYear": {
          "label": "Сдача",
          "from": "Сдача от {quarter} кв. {year} г.",
          "to": "Сдача до {quarter} кв. {year} г.",
          "showHandOver": "Показать сданные дома"
        },
        "keysGetting": {
          "label": "Выдача ключей",
          "from": "Выдача ключей от {quarter} кв. {year} г.",
          "to": "Выдача ключей до {quarter} кв. {year} г.",
          "showHandOver": "Показать сданные дома",
          "action": {
            "label": "Выберите срок выдачи"
          }
        },
        "lifts": {
          "label": "Лифты",
          "hasPassenger": {
            "label": "Пассажирские"
          },
          "hasCargo": {
            "label": "Грузовые"
          }
        },
        "materialTypes": {
          "label": "Материал",
          "action": {
            "label": "Выберите материал"
          },
          "aerocreteBlock": {
            "label": "Газобетонный блок"
          },
          "block": {
            "label": "Блочный"
          },
          "boards": {
            "label": "Щитовой"
          },
          "brick": {
            "label": "Кирпичный"
          },
          "foamConcreteBlock": {
            "label": "Пенобетонный блок"
          },
          "gasSilicateBlock": {
            "label": "Газосиликатный блок"
          },
          "monolith": {
            "label": "Монолитный"
          },
          "monolithBrick": {
            "label": "Монолитно-кирпичный"
          },
          "old": {
            "label": "Старый фонд"
          },
          "panel": {
            "label": "Панельный"
          },
          "stalin": {
            "label": "Сталинский"
          },
          "wireframe": {
            "label": "Каркасный"
          },
          "wood": {
            "label": "Деревянный"
          },
          "log": {
            "label": "Бревно"
          },
          "timber": {
            "label": "Брус"
          }
        }
      },
      "allFilters": {
        "label": "Все фильтры",
        "modal": "Еще фильтры"
      },
      "savedFilters": {
        "label": {
          "short": "Сохр. фильтры",
          "full": "Сохраненные фильтры"
        },
        "modal": "Сохраненные фильтры"
      },
      "selectedFilters": {
        "show": "Ещё {value}"
      },
      "button": "Фильтр",
      "resetButton": "Сбросить",
      "actions": {
        "resetFilters": "Сбросить фильтры"
      },
      "guest": {
        "title": {
          "partOne": "Международная",
          "partTwo": "экосистема недвижимости"
        }
      }
    },
    "search": {
      "flat": "квартир не найдено | {count} квартира | {count} квартиры | {count} квартир",
      "mobileFlat": "0 кв. | {count} кв. | {count} кв. | {count} кв.",
      "noFlat": "Нет доступных квартир",
      "shortSearchButton": "{count} квартир | {count} квартира | {count} квартиры | {count} квартир",
      "errors": {
        "first": "Возникла ошибка. Попробуйте обновить страницу.",
        "second": "Возникла ошибка. Пожалуйста, обратитесь в службу поддержки"
      }
    },
    "show": {
      "layouts": "{count} квартир |  {count} квартира |  {count} квартиры |  {count} квартир",
      "flats": "{count} квартир |  {count} квартира |  {count} квартиры |  {count} квартир",
      "buildings": "{count} ЖК"
    },
    "showCount": {
      "layouts": "Планировки не найдены | Найдена {count} планировка | Найдено {count} планировки | Найдено {count} планировок",
      "flats": "Квартир не найдено | Найдена {count} квартира | Найдено {count} квартиры | Найдено {count} квартир",
      "buildings": "ЖК не найдено | в {count} ЖК | в {count} ЖК | в {count} ЖК"
    },
    "sort": {
      "options": {
        "price": {
          "label": "По цене",
          "order": {
            "descending": "Сначала дорогие",
            "ascending": "Сначала дешёвые"
          }
        },
        "popularity": {
          "label": "По популярности",
          "order": {
            "descending": "Сначала популярные",
            "ascending": "Сначала непопулярные"
          }
        },
        "deadline": {
          "label": "По сроку сдачи",
          "order": {
            "descending": "Сначала новые",
            "ascending": "Сначала старые"
          }
        },
        "area": {
          "label": "По площади",
          "order": {
            "descending": "Сначала большие",
            "ascending": "Сначала небольшие"
          }
        },
        "floor": {
          "label": "По этажу",
          "order": {
            "descending": "Сначала на верхних этажах",
            "ascending": "Сначала на нижних этажах"
          }
        },
        "percentage": {
          "label": "По проценту комиссии",
          "order": {
            "descending": "Сначала больший",
            "ascending": "Сначала меньший"
          }
        }
      }
    },
    "showMap": "На карте",
    "mapBuildings": "{count} ЖК | {count} ЖК | {count} ЖК | {count} ЖК"
  },
  "user": {
    "profile": {
      "avatar": {
        "alt": "Аватар пользователя {user}"
      },
      "page": {
        "toggle": {
          "options": {
            "profile": "Профиль",
            "login": "Логин",
            "password": "Пароль"
          }
        },
        "deleteAccount": {
          "title": "Удаление аккаунта",
          "inputTitle": "Укажите причину удаления",
          "buttonTitle": "Удалить аккаунт",
          "sendRequest": "Отправить запрос",
          "toast": {
            "success": "Запрос на удаление аккаунта отправлен",
            "error": "Во время отправки запроса на удаление произошла ошибка"
          }
        }
      },
      "form": {
        "profile": {
          "title": {
            "edit": "Редактировать сотрудника"
          },
          "about": {
            "title": "О пользователе",
            "firstName": {
              "label": "Имя",
              "placeholder": "Введите имя"
            },
            "lastName": {
              "label": "Фамилия",
              "placeholder": "Введите фамилию"
            }
          },
          "contacts": {
            "title": "Контакты",
            "mobilePhone": {
              "label": "Основной телефон",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            },
            "telegramPhone": {
              "label": "Номер Telegram",
              "placeholder": "+7 000 000 00 00"
            },
            "telegramLogin": {
              "label": "Логин Telegram",
              "placeholder": "Логин"
            },
            "whatsappPhone": {
              "label": "Номер Whatsapp",
              "placeholder": "+7 000 000 00 00"
            }
          },
          "toasts": {
            "profile": {
              "success": "Профиль успешно обновлен",
              "error": "Во время обновления профиля произошла ошибка"
            },
            "login": {
              "success": "Логин успешно обновлен",
              "error": "Во время обновления логина произошла ошибка"
            },
            "password": {
              "success": "Пароль успешно обновлен",
              "error": "Во время обновления пароля произошла ошибка"
            }
          }
        },
        "login": {
          "title": "Изменить логин",
          "mobilePhone": {
            "label": "Номер телефона"
          },
          "currentPassword": {
            "label": "Текущий пароль"
          },
          "submit": "Изменить логин"
        },
        "password": {
          "title": "Изменить пароль",
          "password": {
            "label": "Новый пароль"
          },
          "repeatPassword": {
            "label": "Повторите новый пароль"
          },
          "submit": "Изменить пароль"
        }
      },
      "personalCabinet": {
        "goTo": {
          "label": "Перейти в профиль",
          "confirmation": {
            "title": "Подтверждение",
            "text": "Управлять настройками и своим профилем вы можете в нашей CRM Quick Deal.\nЛогин и пароль такой же, как в Estatyx.\nЭто временное решение и в ближайшее время управление профилем и настройками появится в Estatyx.\nЗаодно можете ознакомиться с нашей CRM для агентств недвижимости Quick Deal =)\nСпасибо за понимание."
          }
        }
      }
    }
  },
  "mainMenu": {
    "navLinks": {
      "home": {
        "index": "Главная",
        "market": "Каталог",
        "popular": "Популярное",
        "prices": "Цены",
        "partners": "Партнеры",
        "mortgage": "Ипотека",
        "journal": "Журнал",
        "events": "Мероприятия"
      }
    }
  },
  "navMenu": {
    "title": "Меню",
    "clients": {
      "label": "Режим работы с клиентом",
      "motivation": "Мотивация",
      "crm": "CRM"
    },
    "invite": {
      "buttonTitle": "Пригласить сотрудников",
      "modalTitle": "Пригласительная ссылка",
      "tooltip": "Нажмите, чтобы скопировать",
      "toast": {
        "success": "Ссылка скопирована",
        "changeLink": {
          "success": "Ссылка обновлена",
          "error": "Во время обновления ссылки произошла ошибка"
        }
      }
    },
    "organisationSettings": {
      "buttonTitle": "Настройки"
    },
    "navigation": {
      "suggestions": "Подборки",
      "clients": "Клиенты",
      "home": "Главная",
      "market": "Каталог",
      "journal": "Журнал",
      "events": "Мероприятия",
      "settings": "Настройки"
    },
    "exit": "Выйти",
    "changeList": {
      "label": "Обновления",
      "version": "Версия",
      "changeListItem": "Обновление",
      "badge": "NEW"
    }
  },
  "suggestions": {
    "sidePanel": {
      "displayAllFlats": {
        "button": "Показать все",
        "title": "Сейчас отображаются все кв. из {value} предложенных ЖК",
        "tip": "Чтобы посмотреть все квартиры из определённого ЖК, нажмите на вкладку ЖК вверху справа"
      }
    },
    "content": {
      "buildingEmpty": "В выбранном ЖК нет добавленных квартир.",
      "suggestionEmpty": "В выбранной подборке нет добавленных квартир."
    },
    "buttons": {
      "bookViewing": {
        "label": "Записаться на просмотр",
        "messageForWhatsApp": "Хочу записаться на просмотр квартир из подборки {link}. Когда можем посмотреть?"
      },
      "chatWithManager": {
        "label": "Чат с риелтором"
      }
    },
    "bottomMenu": {
      "contacts": "Контакты"
    },
    "empty": "Подборка пуста",
    "textToSend": "Подборка объектов : {value}"
  },
  "suggestionsActions": {
    "filter": {
      "all": "Все",
      "user": "Мои"
    },
    "create": {
      "title": {
        "full": "Создать подборку",
        "short": "Подборку"
      },
      "placeholder": "Введите название подборки",
      "toast": {
        "success": "Подборка создана успешно"
      }
    },
    "addToSuggestion": {
      "title": "Добавить объекты в подборку",
      "toast": {
        "success": "Объекты добавлены в подборку"
      }
    },
    "removeSuggestion": {
      "toast": {
        "success": "Подборка успешно удалена",
        "error": "Произошла ошибка"
      },
      "confirm": "Вы точно хотите удалить подборку \"{value}\" ?"
    },
    "client": {
      "search": {
        "title": "Найти существующего",
        "placeholder": "Введите ФИО, телефон или e-mail"
      },
      "add": {
        "toast": {
          "success": "Клиент успешно привязан к подборке."
        }
      },
      "remove": {
        "toast": {
          "success": "Клиент успешно отвязан от подборки."
        }
      },
      "create": {
        "title": "Создать нового",
        "fullName": {
          "label": "ФИО клиента",
          "placeholder": "Введите ФИО клиента"
        },
        "mobilePhone": {
          "label": "Телефон",
          "placeholder": "+_ (___) ___-__-__"
        },
        "email": {
          "label": "E-mail",
          "placeholder": "Введите e-mail"
        },
        "submit": "Добавить клиента",
        "toast": {
          "success": "Клиент успешно создан.",
          "error": "При создании клиента произошла ошибка. Пожалуйста обновите страницу и попробуйте еще раз. Если ошибка повторяется, обратитесь в тех.поддержку."
        }
      },
      "noClients": "Клиент не выбран"
    },
    "removeBuildingFromSuggestion": {
      "toast": {
        "success": "Подборка обновлена"
      },
      "confirm": "Вы точно хотите удалить из подборки {value} и все его квартиры?"
    },
    "removeFlatFromSuggestion": {
      "toast": {
        "success": "Подборка обновлена"
      },
      "confirm": "Вы точно хотите удалить из подборки квартиру номер {value}?"
    },
    "copyLink": {
      "toast": {
        "success": "Ссылка на подборку скопирована в буфер обмена"
      }
    },
    "table": {
      "suggestionTitle": "Название подборки",
      "objects": "Объекты",
      "clients": "Клиенты",
      "creatingDate": "Дата создания",
      "itemsCount": {
        "buildings": "{value} ЖК",
        "flats": "{value} кв."
      }
    },
    "view": {
      "empty": "Подборка пуста"
    }
  },
  "clients": {
    "page": {
      "title": "Клиенты",
      "create": {
        "buttonTitle": {
          "full": "Добавить клиента",
          "short": "Клиента"
        }
      }
    },
    "toasts": {
      "booking": {
        "success": "Бронирование прошло успешно",
        "error": "Во время бронирования произошла ошибка"
      },
      "fixation": {
        "success": "Фиксация прошла успешно",
        "error": "Во время фиксации произошла ошибка"
      }
    },
    "tableView": {
      "fullName": "ФИО",
      "email": "Email",
      "mobilePhone": "Телефон",
      "action": "Действие"
    },
    "delete": {
      "toast": {
        "success": "Клиент успешно удалён."
      }
    },
    "table": {
      "label": "Все клиенты",
      "actions": {
        "addToSuggestion": "Добавить в подборку",
        "bookings": "Брони клиента",
        "fixations": "Фиксации клиента",
        "views": "Просмотры клиента",
        "edit": "Редактировать клиента",
        "delete": "Удалить клиента"
      }
    },
    "filter": {
      "categories": {
        "clients": "Клиент",
        "developers": "Застройщики",
        "buildings": "ЖК",
        "assignees": "Ответственный(ая)"
      },
      "selected": "Выбранное",
      "excluded": "Исключенное"
    },
    "fixation": {
      "table": {
        "label": "Фиксации",
        "createDate": "Дата создания",
        "client": "Клиент",
        "developer": "Застройщик(и)",
        "status": "Статус фиксации",
        "endDate": "Фиксация до",
        "assigned": "Ответственный(ая)"
      },
      "form": {
        "progress": "Бронирование",
        "progressStep": "+{value}% к прогрессу",
        "submit": "Зафиксировать",
        "developer": {
          "title": "Застройщик",
          "label": "Выбор застройщика",
          "placeholder": "Список застройщиков",
          "conditions": {
            "general": "Общие условия",
            "custom": "Условия фиксации"
          }
        },
        "user": {
          "title": "Ответственный(ая)",
          "label": "Выбор пользователя",
          "placeholder": "Список пользователей",
          "select": "Выбрать"
        },
        "client": {
          "title": "Клиент",
          "label": "Выбрать клиента",
          "add": "Добавить нового клиента",
          "placeholder": "Список клиентов"
        },
        "comment": {
          "label": "Комментарий",
          "placeholder": "Ваш комментарий"
        }
      },
      "info": {
        "title": "Преимущество",
        "text": "О преимуществах фиксации клиента вы сможете прочитать в нашей статье",
        "link": "Читать здесь"
      },
      "warning": {
        "title": "Общие условия",
        "text": "Если у застройщика не указаны условия фиксации клиента, то срок фиксации клиента",
        "fixPeriod": "14 дней"
      },
      "fixationTerms": {
        "title": "Фиксация",
        "durationInDays": "Срок фиксации",
        "clientsData": "Необходимые данные для фиксации",
        "additionalInfo": "Дополнительная информация"
      },
      "contacts": {
        "title": "Контакты для связи",
        "mobileTitle": "Контакты"
      }
    },
    "booking": {
      "table": {
        "label": "Брони",
        "createDate": "Дата создания",
        "client": "Клиент",
        "developer": "Застройщик",
        "building": "ЖК",
        "flat": "Квартира",
        "paymentType": "Оплата",
        "status": "Статус брони",
        "endDate": "Бронь до",
        "assigned": "Ответственный(ая)"
      },
      "paymentType": {
        "options": {
          "mortgage": "Ипотека",
          "cash": "Наличные"
        }
      },
      "form": {
        "progress": {
          "yourCommission": "Ваша комиссия"
        },
        "deal": {
          "title": "Сделка",
          "label": "Форма оплаты",
          "placeholder": "Выбрать форму оплаты",
          "uploadButton": "Загрузить сканы документов"
        },
        "about": {
          "flat": "О квартире"
        },
        "submit": "Забронировать"
      },
      "warning": {
        "text": "Максимальный срок брони - 3 дня"
      }
    },
    "views": {
      "table": {
        "label": "Просмотры",
        "createDate": "Дата создания",
        "dateView": "Дата просмотра",
        "client": "Клиент",
        "building": "ЖК",
        "flat": "Квартира",
        "assigned": "Ответственный(ая)",
        "comment": "Комментарий",
        "view": "Просмотр",
        "status": "Статус просмотра"
      },
      "statuses": {
        "scheduled": "Назначен",
        "done": "Проведен",
        "toSchedule": "Согласовать дату"
      },
      "actions": {
        "edit": "Редактировать просмотр",
        "add": "Добавить",
        "selectStatus": "Выберите статус",
        "save": "Соранить просмотр",
        "addView": "Добавить просмотр",
        "selectBuilding": "Выбрать ЖК",
        "selectFlat": "Выбрать квартиру"
      },
      "status": "Статус",
      "building": "ЖК",
      "chessboard": "Шахматка",
      "aboutClient": "О клиенте",
      "toasts": {
        "create": {
          "success": "Просмотр успешно создан",
          "error": "Произошла ошибка при создании просмотра"
        },
        "update": {
          "success": "Просмотр успешно обновлен",
          "error": "Произошла ошибка при обновлении просмотра"
        }
      }
    }
  },
  "mortgage": {
    "label": "Ипотека",
    "calculator": {
      "inputs": {
        "price": {
          "label": {
            "full": "Стоимость недвижимости",
            "short": "Стоимость"
          }
        },
        "initialPayment": {
          "label": {
            "full": "Первоначальный взнос",
            "short": "ПВ"
          }
        },
        "term": {
          "label": "Срок",
          "suffix": "лет | год | года | лет"
        },
        "stake": {
          "label": "Процентная ставка",
          "suffix": "год"
        }
      },
      "info": {
        "monthlyPayment": "Ежемесячный платеж",
        "recommendedIncome": "Рекомендуемый доход"
      }
    },
    "table": {
      "bank": {
        "label": "Банк"
      },
      "stake": {
        "label": "Ставка",
        "stakeRange": {
          "inText": "от {value}%",
          "default": "От {value}%"
        }
      },
      "maxSum": {
        "label": "Сумма",
        "range": "до {sum}"
      },
      "term": {
        "label": "Срок",
        "termRange": "Срок не выбран | {count} год | {count} года | {count} лет"
      },
      "monthlyPayment": {
        "label": "Еж. платеж"
      },
      "initialPayment": {
        "label": {
          "full": "Первый взнос",
          "short": "I взнос"
        },
        "initialPaymentRange": "от {value}%"
      }
    },
    "schedule": {
      "title": "График платежей по кредиту",
      "price": "Стоимость",
      "creditAmount": "Сумма кредита",
      "term": "Срок",
      "overpayment": "Переплата",
      "table": {
        "date": "Дата",
        "remainder": "Остаток",
        "stakes": "Проценты",
        "redeemed": "Погашено",
        "payment": "Платеж"
      }
    },
    "mortgageActions": {
      "showMortgageList": "Программы не найдено | Просмотреть {count} программу | Просмотреть все {count} программы | Просмотреть все {count} программ",
      "showSchedule": "График платежей",
      "applyOnline": "Оформить онлайн"
    }
  },
  "files": {
    "label": "Файлы",
    "actions": {
      "link": "Скопировать",
      "go": "Перейти",
      "show": "Посмотреть все"
    },
    "toast": {
      "success": "Ссылка на файл скопирована в буфер обмена"
    }
  },
  "chessboard": {
    "house": {
      "empty": "В выбранном доме нет секций"
    },
    "section": {
      "label": "Секция",
      "empty": "В выбранном ЖК нет корпусов"
    },
    "floorNumber": {
      "label": "Этаж"
    },
    "modal": {
      "title": "Информация о квартире"
    },
    "empty": "В выбранном доме нет квартир, удовлетворяющих заданным фильтрам"
  },
  "toast": {
    "error": "Произошла ошибка ",
    "title": {
      "success": "Успешно",
      "info": "Подсказка",
      "warning": "Предупреждение",
      "danger": "Подсказка"
    }
  },
  "errorPage": {
    "notFound": "Такой страницы не существует!",
    "forbidden": "Доступ запрещён!",
    "backLink": "Вернуться на главную"
  },
  "map": {
    "actions": {
      "showGrid": "Показать плиткой",
      "openFilter": "Фильтр",
      "paintOn": "Выделить область",
      "painting": "Начните рисовать"
    },
    "building": {
      "notFound": "ЖК в области не найдено"
    },
    "paintedGeometries": "Нет выделенных областей | {count} область на карте | {count} области на карте | {count} областей на карте"
  },
  "footer": {
    "copyright": {
      "title": "©{year} ООО «МЭН» Все права защищены.",
      "subtitle": "При использовании материалов гиперссылка обязательна."
    },
    "subscribe": {
      "title": "Подписаться на рассылку",
      "inputPlaceholder": "Почта",
      "button": "Подписаться"
    }
  },
  "partners": {
    "title": "Партнеры",
    "toggle": {
      "developers": "Застройщики",
      "agencies": "Агенства",
      "banks": "Банки"
    },
    "developers": {
      "houses": {
        "count": "Домов нет | {count} дом | {count} дома | {count} домов",
        "built": "Домов нет | сдан в | сданы в | сдано в",
        "unfinished": "строится в"
      }
    },
    "agency": {
      "offers": "Предложений нет | {count} предложение | {count} предложения | {count} предложений"
    },
    "bank": {
      "mortgage": "Ипотека от {stake}%"
    }
  },
  "journal": {
    "title": "Журнал",
    "goButton": "Перейти в журнал",
    "type": {
      "options": {
        "news": "Новость | Новость | Новости | Новости",
        "promotion": "Акция | Акция | Акции | Акции",
        "article": "Статья | Статья | Статьи | Статьи",
        "review": "Обзор | Обзор | Обзоры | Обзоры",
        "training": "Обучение | Обучение | Обучения | Обучения",
        "webinar": "Вебинар | Вебинар | Вебинары | Вебинары",
        "excursion": "Экскурсия | Экскурсия | Экскурсии | Экскурсии"
      }
    },
    "form": {
      "title": {
        "add": "Добавить контент"
      },
      "sectionTitle": {
        "content": "Контент",
        "object": "Объект",
        "author": "Автор",
        "date": "Дата проведения",
        "duration": "Длительность",
        "seats": "Кол-во мест"
      },
      "fields": {
        "title": {
          "label": "Заголовок",
          "placeholder": "Заголовок статьи"
        },
        "text": {
          "label": "Текст",
          "placeholder": "Текст статьи"
        },
        "shortText": {
          "label": "Краткое описание",
          "placeholder": "Текст статьи"
        },
        "promotionType": {
          "label": "Тип акции",
          "placeholder": "Выбрать",
          "options": {
            "discount": "Скидка",
            "sale": "Распродажа",
            "promotion": "Акция",
            "increasedPercentage": "Повышенный процент",
            "increasedPercentageShort": "Повыш. процент"
          }
        },
        "file": {
          "button": "Загрузить превью"
        },
        "bannerFile": {
          "button": "Загрузить баннер"
        },
        "video": {
          "label": "Добавить видео",
          "placeholder": "Ссылка на видео",
          "caption": "Данное видео будет скрыто за превью к статье, но пользователь сможет его просмотреть",
          "presentationVisible": "Не отображать в презентации"
        },
        "developer": {
          "label": "Застройщик",
          "placeholder": "Список застройщиков",
          "isAll": "Все застройщики",
          "isManagerRequestNeeded": "Уточняйте у менеджера"
        },
        "building": {
          "label": "ЖК",
          "placeholder": "Список ЖК",
          "isAll": "Все ЖК",
          "isManagerRequestNeeded": "Уточняйте у менеджера"
        },
        "country": {
          "label": "Страна",
          "placeholder": "Список стран",
          "isAll": "Все страны",
          "isManagerRequestNeeded": "Уточняйте у менеджера"
        },
        "address": {
          "label": "Регион/Город",
          "placeholder": "Список регионов/городов",
          "isAll": "Все регионы/города",
          "isManagerRequestNeeded": "Уточняйте у менеджера"
        },
        "authorship": {
          "label": "Авторство",
          "options": {
            "estatyx": "Estatyx",
            "company": "Компания"
          }
        },
        "company": {
          "label": "Выбрать компанию",
          "placeholder": "Список компаний"
        },
        "user": {
          "label": "Выбрать сотрудника компании",
          "placeholder": "Список сотрудников"
        },
        "date": {
          "label": "Дата проведения",
          "placeholder": "Выберите дату проведения"
        },
        "dateRange": {
          "label": "Выбрать дату"
        },
        "time": {
          "label": "Время"
        },
        "registrationEndDate": {
          "label": "Дата окончания записи",
          "placeholder": "Выберите дату окончания записи",
          "validation": "{endDate} не может быть позже чем {startDate}"
        },
        "duration": {
          "label": "Длительность",
          "unlimited": "Бессрочно"
        },
        "durationInDays": {
          "label": "Дней"
        },
        "durationInHours": {
          "label": "Часов"
        },
        "seats": {
          "label": "Мест для записи",
          "placeholder": "Введите количество свободных мест",
          "checkbox": "Неограниченное кол-во мест"
        }
      },
      "toast": {
        "create": {
          "success": "Статья успешно создана"
        },
        "edit": {
          "success": "Статья успешно отредактирована"
        }
      }
    },
    "search": {
      "notFound": "Новости не найдены"
    },
    "filter": {
      "withVideo": "Только с видео",
      "promotions": "Акции",
      "buildings": "ЖК",
      "developers": "Застройщики"
    },
    "page": {
      "video": "Видео",
      "event": {
        "info": {
          "text": "Чтобы принять участие в мероприятии,",
          "login": "войдите",
          "signUp": "зарегистрируйтесь"
        }
      }
    }
  },
  "events": {
    "page": {
      "title": "Мероприятия"
    },
    "mySignUps": "Мои записи на мероприятия",
    "seatsCount": "Осталось {count} мест | Осталось {count} место | Осталось {count} места | Осталось {count} мест",
    "status": {
      "seatsUnlimited": "Места есть",
      "noSeats": "Мест не осталось",
      "registrationCompleted": "Запись завершена"
    },
    "filter": {
      "name": "Поиск..."
    },
    "search": {
      "notFound": "Мероприятия не найдены"
    },
    "actions": {
      "signUp": "Записаться",
      "canсelSignUp": "Отменить запись",
      "noSeats": "Мест нет",
      "registrationCompleted": "Запись завершена",
      "eventEnded": "Мероприятие завершено"
    },
    "toast": {
      "signUp": {
        "success": "Вы успешно записались на мероприятие!"
      },
      "canсelSignUp": {
        "success": "Запись отменена!"
      },
      "error": "Во время загрузки мероприятий произошла ошибка"
    },
    "tooltipHint": "Запись доступна только зарегистрированным пользователям"
  },
  "popularity": {
    "suggestionsAdditionsCount": "Добавлено в подборку за {count} месяца",
    "totalSuggestionsAdditionsCount": "За всё время добавлено в подборку {count} раз | За всё время добавлено в подборку {count} раза | За всё время добавлено в подборку {count} раза | За всё время добавлено в подборку {count} раз"
  }
}